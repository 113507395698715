<template>
    <div class="login">
        <el-container>
            <el-header height="120px">
                <div class="type_area">
                    <div class="logo">

                        <img src="@/assets/images/login/hrlogo.png" class="logo_left_img_one" alt="">

                        <!-- <a href="javascript:void(0);" class="logo_right_yg">
                            <span class="logo_right_yg_span">
                                <span style="font-family: PingFangSC-Medium, PingFangSC-Medium;font-weight: normal;font-size: 14px;color: #333333;">服务热线</span>
                                <br>
                                <span style="font-family: SFU DIN Mittelschrift, SFU DIN Mittelschrift;font-weight: 400;font-size: 32px;color: #333333;">0551-6367603</span>
                            </span>
                        </a> -->
                    </div>
                    <!-- <p class="login-tel">客服电话： 0551-62622762</p> -->
                </div>
            </el-header>
            <el-main>
                <div class="content">
                    <div class="login-warpper middle_s">
                        <div class="login-modal">
                            <div class="login-title">会员登录</div>
                            <div class="login-form">
                                <div class="login-form__item">
                                    <input type="text" placeholder="请输入用户名" name="user" id="loname" v-model="form.user">
                                    <!-- icon -->
                                    <img class="el-icon-user" src="../../assets/images/login/man.svg" alt="">
                                </div>
                                <div class="login-form__item" style="position:relative;">
                                    <input :type="pwdType?'text':'password'" placeholder="请输入登录密码" name="password" id="lopassword" v-model="form.password">
                                    <!-- icon -->
                                    <img class="el-icon-lock" src="../../assets/images/login/lock.svg" alt="">

                                    <img v-if="pwdType" style="cursor: pointer;position:absolute;right:10px;top:15px;" @click="pwdType=!pwdType" src="../../assets/images/login/eye.svg" alt="">
                                    <img v-if="!pwdType" style="cursor: pointer;position:absolute;right:10px;top:15px;" @click="pwdType=!pwdType" src="../../assets/images/login/eye-close.svg" alt="">
                                </div>
                                <div class="login-form__item login-form__code">
                                    <div class="login-form__center">
                                        <div>
                                            <input type="text" id="locha" placeholder="验证码" name="code" style=" padding-left: 40px;" size class="identifying_code" v-model="form.code" @keydown="addByEnterKey">
                                            <img class="el-icon-code" src="../../assets/images/login/dun.svg" alt="">
                                        </div>
                                        <!-- `http://mall.huida.cc`+ -->
                                        <!-- <img v-show="show" id="locha_captcha_img" :src="`https://b2bmall.hrjkjt.com/visitor/captcha?${refresh}`" height="40" width="80" style="cursor:pointer;vertical-align:bottom; " alt=""> -->
                                        <img v-show="show" id="locha_captcha_img" :src="`${baseURL}/visitor/captcha?${refresh}`" height="40" width="80" style="cursor:pointer;vertical-align:bottom; " alt="">
                                        <a id="locha_reset" style="margin-top:13px;font-size:12px;color:#999999;cursor:pointer;" @click="changeRe()">换一张</a>
                                    </div>
                                </div>
                                <div class="login-form__item login-form__checkbox">
                                    <el-checkbox v-model="checked" id="free_login" style="vertical-align: top;" class="valig" label="永久记住密码"></el-checkbox>
                                </div>
                                <!-- <div style="font-size:14px;text-align:left;padding-left:20px;padding-bottom:20px;">
                                    <el-checkbox :value="userAgree" @change="handleRadioChange"></el-checkbox>
                                    <span @click="handleRadioChange">我已阅读并同意</span>
                                    <el-link :underline="false" type="primary" class="user-agree" @click="doAgree">《用户协议》</el-link>
                                    <el-dialog :visible.sync="agreeVisible" width="70%" class="abow_dialog">
                                        <span v-html="agreeContent"> </span>
                                        <div style="display: flex;
                        justify-content: center;
                        align-items: center;margin-bottom: 10px;"><el-button class="agreeBtn" type="primary" @click="agreeUser">同意</el-button></div>

                                    </el-dialog>
                                </div> -->
                                <div class="login-form__item">
                                    <div v-if="userAgree" class="login-form__button" @click="login()">登录</div>
                                    <div v-else class="login-form__button-false" @click="toast">登录</div>
                                </div>
                                <div class="register">
                                    <a href="#" @click.prevent="forgetPwd">忘记密码</a>
                                    <span></span>
                                    <!-- <a href="#" @click.prevent="$router.push('/register')">立即注册</a> -->
                                    <a href="https://b2bmall.hrjkjt.com/visitor/regis" >立即注册</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>

            <el-footer style="height:100%;">
                <div style="background:#F4F4F4;padding:50px 0;">
                    <div class="type_area" style="display:flex;justify-content: space-between;">
                        <div style="display:flex;">
                            <div style="margin-right:10px;"><img src="../../assets/images/login/loginload.svg" alt=""></div>
                            <div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">下载华人健康手机APP</div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: 400;font-size: 12px;color: #666666;">扫描二维码，下载华人健康APP商城</div>
                            </div>
                        </div>
                        <div style="display:flex;">
                            <div style="margin-right:10px;"><img src="../../assets/images/login/loginman.svg" alt=""></div>
                            <div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">注册会员</div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: 400;font-size: 12px;color: #666666;">选择您的药店身份，上传信息，注册账号</div>
                            </div>
                        </div>
                        <div style="display:flex;">
                            <div style="margin-right:10px;"><img src="../../assets/images/login/logindun.svg" alt=""></div>
                            <div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">审核认证</div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: 400;font-size: 12px;color: #666666;">信息无误，工作人员将在24小时内审核
                                    通过您的信息</div>
                            </div>
                        </div>
                        <div style="display:flex;">
                            <div style="margin-right:10px;"><img src="../../assets/images/login/logincar.svg" alt=""></div>
                            <div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">采购下单</div>
                                <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: 400;font-size: 12px;color: #666666;">在平台选择商品，填写地址，发票信息</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="type_area" style="padding:50px 0 ;display:flex;">
                    <div class="footer_middle">
                        <div class="footer_middle_left">
                            <img class="left_img" src="@/assets/images/index/footer_jt.svg" alt="">
                            <div class="left_item" v-for="(item,index) in articleList" :key="index">
                                <span class="left_item_title">{{item.categoryName}}</span>
                                <span class="left_item_text" v-for="(item1,index1) in item.childen" :key="index1" @click="goToExternalPage(item1.url)">{{item1.title}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left:200px;display:flex;text-align:center;">
                        <div>
                            <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">华人商学院</div>
                            <div>
                                <img style="width:100px;height:100px;" src="https://b2bmall.hrjkjt.com/picture/home/shangxueyuan.jpg" alt="">
                            </div>
                        </div>
                        <div>
                            <div style="font-family: Microsoft YaHei, Microsoft YaHei;font-weight: bold;font-size: 14px;color: #333333;">商城APP</div>
                            <div>
                                <img style="width:110px;height:102px;" src="https://b2bmall.hrjkjt.com/uploadfile/code/down_code.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </el-footer>
        </el-container>
        <!-- <el-dialog :visible.sync="dialogVisible" width="20%" :show-close="false" center top="30vh">
            <div slot="title" class="header-title">
                <span style="font-weight:700">账号密码找回</span>
            </div>
            <span style="font-size:14px;line-height:20px;">
                <p>温馨提示</p>
                <p>忘记账号、密码请联系业务员，或者拨打客服电话进行找回。</p>
                <p>客服电话：<span style="color:#F92B2B;">0551-62622762</span></p>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" style="width:100%;background-color:#0054BC;color:#fff">关闭</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
// import MyFooter from '../Layout/components/MyFooter.vue';
// import { fetchCode, fetchCapt, login } from "@a/login";
export default {
    name: 'login',
    // components: {
    //     MyFooter
    // },
    data() {
        return {
            form: {
                user: '',
                password: '',
                code: '',
                free_login: 0
            },
            userAgree: true,
            img: '',
            refresh: -1,
            show: false,
            checked: false,
            dialogVisible: false,
            disabled: false,
            agreeVisible: false,
            agreeContent: '',
            articleList: [],
            pwdType: false
        }
    },
    created() {
        if (localStorage.getItem('user') == '' || localStorage.getItem('user') === null) {
            this.checked = false
        } else {
            this.checked = true
            this.form.user = localStorage.getItem('user')
            this.form.password = localStorage.getItem('password')
        }

        this.getPcArticle()
    },
    mounted() {
        this.getCode()
    },

    methods: {
        getPcArticle() {
            this.$api.pcArticle().then((res) => {
                if (res.data.code === 200) {
                    this.articleList = res.data.data
                }
            });
        },
        goToExternalPage(url) {
            // console.log(url)
            // 将URL设置为你想要跳转的外部页面
            // 页面跳转增加loading
            const loading = this.$loading();
            window.location.href = url;
            loading.close()
            //   window.open(url)
        },
        agreeUser() {
            this.agreeVisible = false
            this.userAgree = true
        },
        handleRadioChange() {
            this.userAgree = !this.userAgree
        },
        doAgree() {
            this.agreeVisible = true
            this.disabled = true
            // this.$nextTick(async () => {
            //     const res = await $.get('https://b2bapi.ahhdyy.net/v1/agreement')
            //     this.agreeContent = res
            // })

        },

        async getCode() {
            this.refresh = new Date().getTime() //时间戳
            // console.log(this.baseURL,'baseURL');
            // this.img = `https//b2bmall.hrjkjt.com/visitor/captcha?${this.refresh}`; //测试
            //   this.img = `b2bmall.hrjkjt.com/visitor/captcha?${this.refresh}`;   正式
            this.show = true
        },
        async getCapt() {
            const fd = new FormData()
            fd.append('code', this.form.code)
            const res = await this.$api.verificationCode(fd)
            if (res.data === 0) {
                // console.log('验证码', this.form.code)
                this.$message({
                    message: '验证码错误',
                    type: 'warning'
                });
            } else {
                this.getLogin()
            }
        },
        getLogin() {
            if (this.checked) {
                this.form.free_login = 1
            } else {
                this.form.free_login = 0
                localStorage.setItem('user', '')
                localStorage.setItem('password', '')
            }
            const fd = new FormData()
            fd.append('code', this.form.code)
            fd.append('user', this.form.user)
            fd.append('password', this.form.password)
            fd.append('free_login', this.form.free_login)
            this.$api.login(fd).then((res) => {
                if (res.data.code !== 200) {
                    // this.$message.warning(res.data.errMsg);
                } else {
                    localStorage.setItem('huaren-token', 1314152)
                    this.$store.commit('setStoreToken', 1314152)
                    if (this.checked) {
                        localStorage.setItem('user', this.form.user)
                        localStorage.setItem('password', this.form.password)
                    }
                    if (res.data.code === 200) {
                        localStorage.setItem('roleFrist', 1)
                        this.$router.push('/')
                    }
                    // window.location.href = '/'
                }
            });
        },
        changeRe() {
            this.refresh = new Date().getTime() //时间戳
            this.getCode()
        },
        login() {
            // this.getCapt()
            console.log(this.form);
            if (this.form.user == '' || this.form.password == '') {
                this.$message.warning('用户名和密码不能为空');
            } else {
                if (this.form.code == '') {
                    this.$message.warning('验证码为空');
                } else {
                    this.getCapt()
                }
            }
        },
        addByEnterKey(e) {
            //Enter键的代码就是13
            if (e.keyCode == 13) {
                this.login();
            }
        },
        forgetPwd(e) {
              this.$router.push('/forgetPwd')
        },

        toast() {
            this.$message({
                message: '请阅读并同意《用户协议》',
                type: 'error',
                offset: 100
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.footer_middle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 800px;
    .footer_middle_left {
        flex: 1;
        display: flex;
        align-items: flex-start;

        .left_img {
            margin-right: 24px;
            padding-top: 4px;
        }

        .left_item {
            flex: 1;
            display: flex;
            flex-direction: column;

            .left_item_title {
                font-weight: bold;
                font-size: 16px;
                color: #333333;
                margin-bottom: 10px;
            }

            .left_item_text {
                font-size: 14px;
                color: #666666;
                margin-bottom: 4px;
                text-indent: 5px;
                transition: 0.3s;

                &:hover {
                    color: #999;
                    cursor: pointer;
                }
            }
        }
    }
    .footer_middle_right {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .txt {
            margin-top: 9px;
            padding-right: 10px;
            font-size: 14px;
            color: #666666;
        }

        .phone {
            padding-right: 10px;
            font-weight: 600;
            font-size: 26px;
            color: #d63103;
        }
    }
}
.abow_dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    // overflow: hidden;
}
.abow_dialog .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    // overflow: hidden;
}
::v-deep .abow_dialog .el-dialog__body {
    height: 600px;
    width: 100%;
    padding: 0;
    z-index: 1;
    overflow-y: auto;
    overflow-x: auto;
}
.user-agree {
    vertical-align: top;
    cursor: pointer;
}
input {
    background: none;
    outline: none;
    border: none;
}
input:focus {
    border: none;
}
input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #ccc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ccc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ccc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #ccc;
}
img {
    display: block;
    border: none;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
.login {
    width: 100%;
    position: absolute;
    overflow: hidden;
}
.content {
    height: 500px;
    // width: 100%;
    // background-color: #ffffff;
    // background-color: antiquewhite;
    background-size: 100% 100%;
    background-image: url(../../assets/images/login/loginhb.png);
    background-repeat: no-repeat;
}
.el-header {
    margin: 0;
    padding: 0;
}
.el-main {
    margin: 0;
    padding: 0;
}
.el-footer {
    margin: 0;
    padding: 0;
}
.type_area {
    .logo {
        width: 100%;
        height: 110px;
        font-family: 'SimSun';
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo_left {
            // width: 748px;
            // height: auto;
            float: left;
            margin: 10px 0 0 0px;
            display: block;
        }
        .logo_left .logo_left_img_one {
            width: 100%;
            position: relative;
        }
        .logo_right_yg {
            height: 90px;
            float: right;
            // margin: 10px 0 0 10px;
            display: flex;
            .logo_right_yg_span {
                font-size: 12px;
                display: inline-block;
                position: relative;
                // top: 31px;
                margin-top: 10px;
                text-align: right;
                line-height: 30px;
            }
        }
    }
    .login-tel {
        font-size: 12px;
        color: #d63103;
        text-align: right;
    }
}
.login-modal {
    width: 346px;
    height: 440px;
    // margin:0 auto;
    text-align: center;
    background-color: #ffffff;
    // padding:20px;
    box-sizing: border-box;
    text-align: left;
    button {
        display: block;
    }
}
.login-warpper {
    // text-align: right;
    padding-top: 20px;
    display: flex;
    justify-content: end;
}
.middle_s {
    width: 1280px;
    margin: 0 auto;
}
.login-title {
    color: #333333 !important;
    box-sizing: border-box;
    text-align: center;
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    font-weight: 700;
    color: #d63103;
    // border-bottom: 1px solid #eeeeee;
}
.login-form {
    padding-top: 20px;
    text-align: center;
    position: relative;
    height: 366px;
}
.login-form__item {
    display: inline-block;
    text-align: center;
    height: 40px;
    width: 304px;
    margin-bottom: 20px;
    position: relative;
    font-size: 20px;
}
.register {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        color: #666666;
        font-size: 14px;
        &:hover {
            color: #888f8e;
        }
    }
    span {
        width: 1px;
        height: 12px;
        background-color: #d8d8d8;
        margin: 0 10px;
    }
}
.login-form__item > input {
    font-size: 14px;
    width: 100%;
    height: 100%;
    border: 1px solid #d8d8d8;
    color: #333;
    padding-left: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
}
input {
    border-radius: 0;
}
.login-form__code {
    text-align: left;
    // margin-bottom:75px;
    // display: flex;
}
.login-form__code input {
    border: 1px solid #d8d8d8;
    color: #333;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 10px;
    width: 144px;
    height: 40px;
    margin-right: 20px;
}
.identifying_code {
    width: 72px;
    border: 1px solid #ccc;
    font-size: 14px;
    font-family: arial, SumSun;
    color: #999;
    height: 16px;
    padding: 9px 5px;
}
img {
    border: none;
}
.login-form__code #locha_reset {
    color: #007dd4 !important;
    margin-left: 5px;
}
.login-form__center {
    display: flex;
}
#free_login {
    vertical-align: -2px;
    color: #ddd;
}
.login-form__checkbox label {
    display: inline-block;
    vertical-align: auto;
    padding-top: 2px;
}
.login-form__checkbox {
    height: 20px;
    line-height: 20px;
    text-align: left;
    margin-top: 10px;
    font-size: 12px;
    border: 0;
}
input[type='checkbox' i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}
.login-form__checkbox > input {
    padding-left: 0;
    width: auto;
    border: 0;
}
.login-form__button {
    height: 40px;
    border-radius: 3px;
    background-color: #1a9dff;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    width: 100%;
    border: 0;
    color: #fff;
    // background-color:linear-gradient( 230deg, #1A9DFF 0%, #0687FE 100%) !important;
    display: block;
    cursor: pointer;
}
.login-form__button-false {
    height: 40px;
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    width: 100%;
    border: 0;
    color: #fff;
    background-color: #7fa9dd !important;
    display: block;
    cursor: not-allowed;
}
.el-icon-lock {
    position: relative;
    top: -29px;
    left: 10px;
    color: #bbb;
}
.el-icon-code {
    position: relative;
    top: -29px;
    left: 10px;
    color: #bbb;
}
.el-icon-user {
    position: relative;
    top: -29px;
    left: 10px;
    color: #bbb;
}
.el-checkbox {
    margin-right: 5px;
}
</style>
